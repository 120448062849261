import { addAlert } from '../../utilities/helpers'

import {
  findTransportationProfiles,
  getBusStopsForRoute,
  getRouteFareByOriginAndDestination,
  getTransportationRoutes,
  getTransportationProfileGeolocationMetadata
} from '../../services'

let lastUsedBusId = null
let lastUsedTransportationProfile = null

export default {
  name: 'search-route-fare',
  props: {
    onFareSearchResult: {
      required: true,
      type: Function
    },
    onCancelFareSearch: {
      type: Function
    },
    onSelectedBusRouteData: {
      type: Function
    }
  },
  data () {
    return {
      isSearchingBus: false,
      isSearchingFare: false,
      busRoutes: [],
      selectedBusRouteId: null,
      busId: '',
      busRouteBusStopsCache: {},
      originBusStops: [],
      destinationBusStops: [],
      selectedOriginBusStop: null,
      selectedDestinationBusStop: null,
      lastBusStopIdFromMetadata: null,
      viewOriginBusStopSearch: false,
      viewDestinationBusStopSearch: false,
      originBusStopKeyword: '',
      destinationBusStopKeyword: '',
      routeFare: null
    }
  },
  computed: {
    selectedBusRoute () {
      return this.busRoutes.find((o) => o.value === this.selectedBusRouteId)
    }
  },
  watch: {
    busRoutes (val) {
      if (val?.length) {
        (async () => {
          for (const busRoute of val) {
            if (!this.busRouteBusStopsCache[busRoute.value]) {
              try {
                this.busRouteBusStopsCache[busRoute.value] = await getBusStopsForRoute(busRoute.value)
              } catch (error) {
                console.error(error)
                addAlert({ message: error, type: 'error' })
                return
              }
            }
          }

          this.refreshOriginAndDestinationBusStops()
        })()
      }
    },
    selectedBusRoute (val) {
      this.$props.onSelectedBusRouteData && this.$props.onSelectedBusRouteData({
        selectedBusRoute: val,
        selectedOriginBusStop: this.selectedOriginBusStop,
        selectedDestinationBusStop: this.selectedDestinationBusStop
      })
    },
    selectedBusRouteId (val) {
      this.selectedOriginBusStop = null
      this.selectedDestinationBusStop = null
      this.refreshOriginAndDestinationBusStops(val)
    },
    selectedDestinationBusStop (val) {
      this.refreshOriginAndDestinationBusStops()

      this.$props.onSelectedBusRouteData && this.$props.onSelectedBusRouteData({
        selectedBusRoute: this.selectedBusRoute,
        selectedOriginBusStop: this.selectedOriginBusStop,
        selectedDestinationBusStop: val
      })
    },
    selectedOriginBusStop (val) {
      this.refreshOriginAndDestinationBusStops()

      this.$props.onSelectedBusRouteData && this.$props.onSelectedBusRouteData({
        selectedBusRoute: this.selectedBusRoute,
        selectedOriginBusStop: val,
        selectedDestinationBusStop: this.selectedDestinationBusStop
      })
    },
    originBusStopKeyword (val) {
      this.filterOriginBusStops(val)
    },
    destinationBusStopKeyword (val) {
      this.filterDestinationBusStops(val)
    }
  },
  mounted () {
    this.busId = lastUsedBusId || ''
    this.transportationProfile = lastUsedTransportationProfile || null

    if (this.busId) {
      this.searchBusId()
    }
  },
  methods: {
    openOriginBusStopSearch () {
      this.originBusStopKeyword = ''
      this.viewOriginBusStopSearch = true
    },
    openDestinationBusStopSearch () {
      this.destinationBusStopKeyword = ''
      this.viewDestinationBusStopSearch = true
    },
    closeOriginBusStopSearch (selectedItem) {
      this.selectedOriginBusStop = selectedItem
      this.viewOriginBusStopSearch = false
    },
    closeDestinationBusStopSearch (selectedItem) {
      this.selectedDestinationBusStop = selectedItem
      this.viewDestinationBusStopSearch = false
    },
    filterOriginBusStops (keywords) {
      if (keywords) {
        const keywordParts = keywords.split(' ').map((o) => o.trim()).filter(Boolean)
        this.originBusStops = this.originalOriginBusStops.filter((o) => keywordParts.every((k) => o.name.toLowerCase().indexOf(k.toLowerCase()) > -1))
      } else {
        this.originBusStops = this.originalOriginBusStops.slice()
      }
    },
    filterDestinationBusStops (keywords) {
      if (keywords) {
        const keywordParts = keywords.split(' ').map((o) => o.trim()).filter(Boolean)
        this.destinationBusStops = this.originalDestinationBusStops.filter((o) => keywordParts.every((k) => o.name.toLowerCase().indexOf(k.toLowerCase()) > -1))
      } else {
        this.destinationBusStops = this.originalDestinationBusStops.slice()
      }
    },
    refreshOriginAndDestinationBusStops (latestSelected) {
      const selectedBusRouteId = latestSelected || this.selectedBusRouteId

      if (selectedBusRouteId) {
        let busRouteBusStops = this.busRouteBusStopsCache[selectedBusRouteId]

        if (Array.isArray(busRouteBusStops)) {
          if (this.lastBusStopIdFromMetadata) {
            busRouteBusStops = busRouteBusStops.slice(busRouteBusStops.findIndex((o) => o.id === this.lastBusStopIdFromMetadata) + 1)
          }

          this.originBusStops = this.selectedDestinationBusStop ? busRouteBusStops.slice(0, busRouteBusStops.findIndex((o) => o.id === this.selectedDestinationBusStop.id)) : busRouteBusStops.slice()
          this.destinationBusStops = this.selectedOriginBusStop ? busRouteBusStops.slice(busRouteBusStops.findIndex((o) => o.id === this.selectedOriginBusStop.id) + 1) : busRouteBusStops.slice()
        }
      } else {
        this.originBusStops = []
        this.destinationBusStops = []
      }

      this.originalOriginBusStops = this.originBusStops.slice()
      this.originalDestinationBusStops = this.destinationBusStops.slice()
    },
    async executeByEnterKeyboard (keyboardEvent, callback) {
      if (keyboardEvent?.key === 'Enter') {
        callback()
      }
    },
    async searchBusId (keyboardEvent) {
      if (!this.busId * this.busId < 4) {
        addAlert({ message: 'Please enter a valid bus ID', type: 'error' })
        return
      }

      try {
        this.isSearchingBus = true
        this.selectedBusRouteId = null
        this.lastBusStopIdFromMetadata = null

        const transportationProfileMetadata = await getTransportationProfileGeolocationMetadata({
          shortcodeId: this.busId
        })

        if (transportationProfileMetadata) {
          this.lastBusStopIdFromMetadata = transportationProfileMetadata.lastBusStopId
        }

        if (!this.transportationProfile || this.transportationProfile.shortcodeId !== this.busId) {
          const transportationProfiles = await findTransportationProfiles({
            shortcodeId: this.busId
          })

          if (transportationProfiles.length) {
            this.transportationProfile = transportationProfiles[0]
          }
        }

        if (!this.transportationProfile) {
          addAlert({ message: 'Bus not found', type: 'error' })
          return
        }

        this.selectedBusRouteId = transportationProfileMetadata?.transportationProfileRouteId

        const busRoutes = await getTransportationRoutes({
          transportationProfileId: this.transportationProfile.id
        })

        if (busRoutes?.length) {
          this.busRoutes = busRoutes.map((o) => ({
            text: o.stRoute?.label || o.profileRouteMetadata.routeLabel,
            value: o.id
          }))
          this.selectedBusRouteId = this.selectedBusRouteId ? busRoutes.find((o) => o.id === this.selectedBusRouteId)?.id : null
          this.selectedBusRouteId = this.selectedBusRouteId ? this.selectedBusRouteId : busRoutes.find((o) => o.isPrimary)?.id || busRoutes[0].id
        } else {
          this.busRoutes = []
        }

        lastUsedBusId = this.busId
        lastUsedTransportationProfile = this.transportationProfile
      } catch (error) {
        console.error(error)
        addAlert({ message: error, type: 'error' })
      } finally {
        this.isSearchingBus = false
      }
    },
    async searchFare () {
      if (!this.selectedOriginBusStop || !this.selectedDestinationBusStop) {
        addAlert({ message: 'Please select origin and destination bus stops', type: 'error' })
        return
      }

      try {
        this.isSearchingFare = true

        const routeFare = await getRouteFareByOriginAndDestination({
          transportationProfileRouteId: this.selectedBusRouteId,
          startLongitude: this.selectedOriginBusStop.longlat[0],
          startLatitude: this.selectedOriginBusStop.longlat[1],
          endLongitude: this.selectedDestinationBusStop.longlat[0],
          endLatitude: this.selectedDestinationBusStop.longlat[1]
        })

        if (!routeFare) {
          throw new Error('Fare not found')
        }

        this.routeFare = routeFare
        this.$props.onFareSearchResult(routeFare)
      } catch (error) {
        console.error(error)
        this.$props.onFareSearchResult(null, error)
      } finally {
        this.isSearchingFare = false
      }
    },
    cancelFareSearch () {
      this.routeFare = null
      this.$props.onCancelFareSearch && this.$props.onCancelFareSearch()
    }
  }
}
