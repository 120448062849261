import signInForm from './sign-in-form/template'
import phoneNumberField from './phone-number-field/template'
import currencyField from './currency-field/template'
import siteAlertsTray from './site-alerts-tray/template'
import mobileMoneyAccountSelector from './mobile-money-account-selector/template'
import stQrcodeStream from './st-qrcode-stream/template'
import verifyPassengerAccess from './verify-passenger-access/template'
import manageMobileMoneyAccounts from './manage-mobile-money-accounts/template'
import stUploader from './st-uploader/template'
import stCalendar from './st-calendar/template'
import searchRouteFare from './search-route-fare/template'
import { pendingLoader, stVideoSnapshot } from '@smarttransit/common-client'

export default {
  'currency-field': currencyField,
  'phone-number-field': phoneNumberField,
  'sign-in-form': signInForm,
  'site-alerts-tray': siteAlertsTray,
  'mobile-money-account-selector': mobileMoneyAccountSelector,
  'verify-passenger-access': verifyPassengerAccess,
  'manage-mobile-money-accounts': manageMobileMoneyAccounts,
  'search-route-fare': searchRouteFare,
  'st-qrcode-stream': stQrcodeStream,
  'st-uploader': stUploader,
  'st-calendar': stCalendar,
  'st-pending-loader': pendingLoader,
  'st-video-snapshot': stVideoSnapshot
}
