<template>
  <div>
    <div class="text-center mb-3"><strong>Bus ID</strong></div>
    <div class="mb-3">
      <v-text-field
        solo
        clearable
        placeholder="Enter ID to search"
        :rules="[(text) => text.length === 0 || text.length >= 4 || 'Bus ID must be 4 or more digits']"
        v-model="busId"
        append-icon="fa fa-search"
        type="tel"
        :disabled="isSearchingFare"
        @keydown="executeByEnterKeyboard($event, searchBusId)"
        @click:append="searchBusId"
      />
    </div>
    <div class="mb-3">
      <v-select
        :disabled="busRoutes.length === 0 || isSearchingFare"
        :loading="isSearchingBus"
        :items="busRoutes"
        v-model="selectedBusRouteId"
        label="Bus Route"
        placeholder="Select route"
        :menu-props="{ offsetY: true }"
        outlined
      ></v-select>
    </div>
    <div class="text-center mb-3"><strong>From</strong></div>
    <div class="mb-3">
      <v-chip
        style="width: 100%"
        :close="Boolean(selectedOriginBusStop)"
        :disabled="isSearchingFare"
        outlined
        @click="openOriginBusStopSearch()"
        @click:close="selectedOriginBusStop = null"
      >
        <span v-if="!selectedOriginBusStop">Select From Stop</span>
        <span v-if="selectedOriginBusStop" style="width: 95%" class="text-truncate">{{selectedOriginBusStop.name}}</span>
      </v-chip>
    </div>
    <div class="text-center mb-3"><strong>To</strong></div>
    <div class="mb-5">
      <v-chip
        style="width: 100%"
        :close="Boolean(selectedDestinationBusStop)"
        :disabled="isSearchingFare"
        color="primary"
        outlined
        @click="openDestinationBusStopSearch()"
        @click:close="selectedDestinationBusStop = null"
      >
        <span v-if="!selectedDestinationBusStop">Select To Stop</span>
        <span v-if="selectedDestinationBusStop" style="width: 95%" class="text-truncate">{{selectedDestinationBusStop.name}}</span>
      </v-chip>
    </div>
    <v-btn block class="mb-3" :loading="isSearchingFare" :disabled="isSearchingFare" @click="searchFare()">Search for fare</v-btn>
    <div>
      <v-btn text block color="warning" @click="cancelFareSearch()">Cancel</v-btn>
    </div>
    <v-dialog
      v-model="viewOriginBusStopSearch"
      width="400"
    >
      <v-card class="mx-auto" tile>
        <v-list dense>
          <v-subheader>
            <div class="ma-2 mt-8" style="width: 100%">
              <v-text-field
                solo
                clearable
                dense
                autofocus
                placeholder="Search or select stop name"
                v-model="originBusStopKeyword"
                append-icon="fa fa-search"
                @click:append="filterOriginBusStops(originBusStopKeyword)"
                @click:close="originBusStopKeyword = null"
              />
            </div>
          </v-subheader>
          <v-list-item
            v-for="(item, i) in originBusStops"
            @click="closeOriginBusStopSearch(item)"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon color="primary" v-text="selectedOriginBusStop && selectedOriginBusStop.id === item.id ? 'fa fa-check' : ''"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{item.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewDestinationBusStopSearch"
      width="400"
    >
      <v-card class="mx-auto" tile>
        <v-list dense>
          <v-subheader>
            <div class="ma-2 mt-8" style="width: 100%">
              <v-text-field
                solo
                clearable
                dense
                autofocus
                placeholder="Search or select stop name"
                v-model="destinationBusStopKeyword"
                append-icon="fa fa-search"
                @click:append="filterDestinationBusStops(destinationBusStopKeyword)"
                @click:close="destinationBusStopKeyword = null"
              />
            </div>
          </v-subheader>
          <v-list-item
            v-for="(item, i) in destinationBusStops"
            @click="closeDestinationBusStopSearch(item)"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon color="primary" v-text="selectedDestinationBusStop && selectedDestinationBusStop.id === item.id ? 'fa fa-check' : ''"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{item.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
